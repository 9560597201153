body[data-theme="alto-contraste"] {
    --background-var1: #000;
    --background-var2: #000;
    --background-var3:#000;
    --onbackground-var1:#ffff00;
    --onbackground-var2: #ffff00;
    --surface-var1: #000;
    --surface-var2:#000;
    --surface-var3: #000;
    --surface-var4: #000;
    --onsurface-var1: #ffff00;
    --onsurface-var2:#ffff00;
    --primary-var1: #000;
    --primary-var2: #000;
    --primary-var3: #000;
    --primary-var4: #000;
    --primary-var5: #000;
    --onprimary-var1: #ffff00;
    --onprimary-var2: #ffff00;
    --primary-inv-var1: #ffff00;
    --primary-inv-var2: #ffff00;
    --primary-inv-var3: #ffff00;
    --primary-inv-var4: #ffff00;
    --primary-inv-var5: #ffff00;
    --onprimary-inv-var1: #000;
    --onprimary-inv-var2: #000;
    --secondary-var1: #000;
    --onsecondary-var1: #ffff00;
    --secondary-inv-var1: #ffff00;
    --onsecondary-inv-var1: #000;
    --red-var1: #000;
    --red-var2: #000;
    --onred-var1: #ffff00;
    --red-inv-var1: #ffff00;
    --red-inv-var2: #ffff00;
    --onred-inv-var1: #000;
    --green-var1: #000;
    --green-var2: #000;
    --ongreen-var1: #ffff00;
    --green-inv-var1: #ffff00;
    --green-inv-var2: #ffff00;
    --ongreen-inv-var1: #000;
    --blue-var1:#000;
    --blue-var2: #000;
    --onblue-var1: #ffff00;
    --blue-inv-var1:#ffff00;
    --blue-inv-var2: #ffff00;
    --onblue-inv-var1: #000;
    --orange-var1: #000;
    --orange-var2:#000;
    --onorange-var1: #ffff00;
    --orange-inv-var1: #ffff00;
    --orange-inv-var2:#ffff00;
    --onorange-inv-var1: #000;
    --disabled-var1: #000;
    --ondisabled-var1: #FAF096;
    --disabled-inv-var1: #FAF096;
    --ondisabled-inv-var1: #000;
    //variables del calendario
    --calendario-primary-purple: #ffff00;
    --calendario-secondary-purple: #ffff00;
    --calendario-shadow-purple: #ffff00;
    --calendario-today-purple: #ffff00;
    --calendario-hover-purple: #ffff00;
    --calendario-deselect-purple: #ffff00;
}
