/*tablas*/
.encabezadoTabla{
    color: var(--onsurface-var1);
    border-bottom: 1px solid var(--onsurface-var2);
    @apply hidden quicksand-700 text-16 pb-3;
    @apply  xl:grid xl:grid-cols-12 xl:mb-0 xl:px-0  xl:gap-3 xl:shadow-none xl:bg-transparent;
}
.filaTabla{
    border-bottom: 1px solid var(--onsurface-var2) !important;
    background-color: var(--onprimary-inv-var1);
    @apply mb-3 p-3 gap-3 text-16 shadow-2;
    @apply grid grid-cols-12;
    @apply xl:mb-0 xl:px-0  xl:shadow-none xl:bg-transparent xl:border-none;
}
.cajaTabla{
    @apply mb-3 p-3 xl:p-5 gap-3 text-13 shadow-2 ;
    @apply grid grid-cols-12;
    background: var(--background-var3); // bg-blanco
    $themes:(
        "clasico":(
            border-bottom: 1px solid var(--background-var1) //border-b border-gris-tabla 
        ),
        "alto-contraste":(
            border: 1px solid var(--onbackground-var1)
        )
    );
    @include ThemesProperties($themes);

}
.fila-12-sm1-xl1{
    @apply col-span-12 sm:col-span-1 xl:col-span-1;
}
.fila-12-sm2-lg-6{
    @apply col-span-12 sm:col-span-2 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-2-sm1-lg12-xl1{
    @apply col-span-2 sm:col-span-1 lg:col-span-12 xl:col-span-1;
}
.fila-6-md4-lg6{
    @apply col-span-6 md:col-span-4 lg:col-span-6 flex flex-col;
} 
.fila-6-md3-lg6{
    @apply col-span-6 md:col-span-3 lg:col-span-6 flex flex-col;
}
.fila-10-sm5-md3-lg6{
    @apply col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-10-sm5-md4-lg6{
    @apply col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-10-sm5-lg6{
    @apply col-span-10 sm:col-span-5 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12-sm3-lg6{
    @apply col-span-12 sm:col-span-3 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12-sm4-lg6{
    @apply col-span-12 sm:col-span-4 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12-sm6-md1-lg6{
    @apply col-span-12 sm:col-span-6 md:col-span-1 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12-sm6-md4-lg6{
    @apply col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12-sm6-md3-lg6{
    @apply col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-6 flex flex-col xl:justify-center;
}
.fila-12{
    @apply col-span-12  flex flex-col xl:justify-center;
}
.fila-12-sm11{
    @apply col-span-12 sm:col-span-11 flex flex-col xl:justify-center;
}
.fila-12-sm11-lg12{
    @apply col-span-12 sm:col-span-11 lg:col-span-12 flex flex-col xl:justify-center;
}
.fila-10-sm11-lg12{
    @apply col-span-10 sm:col-span-11 lg:col-span-12 flex flex-col xl:justify-center;
}
.fila-12-sm5-md4-lg5{
    @apply col-span-12 sm:col-span-5 md:col-span-4 lg:col-span-5 flex flex-col xl:justify-center;
}
.fila-12-sm5-md3-lg5{
    @apply col-span-12 sm:col-span-5 md:col-span-3 lg:col-span-5 flex flex-col xl:justify-center;
}
/*paginacion*/
.hoverBackgroundAlfa {
    &:hover{
        background: rgba(102, 102, 204, 0.26087)
    }
}
.paginaNumero{
    /*text-negro-lista*/
    /*@apply hover:border hover:border-morado-alfa;*/
    @apply w-10 h-10 center raleway-400  cursor-pointer mx-1 rounded-full;
    color: var(--onsurface-var1);
    &:hover{
        border: 1px solid;

        $themes:(
            "clasico":(
                border-color: rgba(102, 102, 204, 0.26087)
            ),
            "alto-contraste": (
                border-color: var(--onsurface-var1)
            )
        );
        @include ThemesProperties($themes);
    }
}
.paginaNumero-activo{
    /*bg-morado text-blanco*/
    @apply  raleway-500 transicion-200 hover:border-0;
    background: var(--primary-inv-var2);
    color: var(--onprimary-inv-var1);

}
/*verificarCajas*/

.verificar-cajas{
    @apply mb-3 p-3 gap-3 border-b border-gris-tabla text-13 shadow-2 bg-blanco ;
    @apply grid grid-cols-12;
}

@media screen and (max-width:1200px) {
    .filaTabla{
        $themes:(
            "clasico":(
                border: none,
            ),
            "alto-contraste": (
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes);
    }
}