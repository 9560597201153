/*Contiene el estilo que unicamente se usa en cada pagina*/
.header-estilo {
  @apply  font-raleway text-17.6  shadow duration-150 fixed  h-16;
    $themes:(
      "alto-contraste":(
        border-bottom: 1px solid var(--onbackground-var1)
      )
    );
    @include ThemesProperties($themes);
}

/*AYUDA--------------------------------------------------------------------------------------------------------*/
.preguntasFrecuentes {
  @apply transition-all duration-500;
  @apply  shadow-interna overflow-hidden z-20;
  background: var(--primary-var5); //bg-morado-claro
  color: var(--onsurface-var1); //text-gris-textos

  $themes:(
    "alto-contraste":(
      border: 1px solid var(--onbackground-var1)
    )
  );
  @include ThemesProperties($themes);

}
.preguntasFrecuentes p {
  @apply leading-6 text-16 pb-5;
}
/*Acordeon*/
.acordeon {
  @apply w-full flex justify-between items-center px-5 py-3;
  @apply  shadow-2 z-30;
  @apply  hover:transform hover:scale-101 transition-all duration-500;
  background-color: var(--background-var3); //bg-blanco
  
  color: var(--primary-inv-var2);
  $themes: (
    "clasico":(
      border-bottom: 1px solid var(--primary-inv-var2)  // border-b border-morado
    ),
    "alto-contraste":(
      border: 1px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);

  &:hover{
    $themes: (
      "clasico":(
        background-color: var(--primary-var5) //hover:bg-morado-claro
      ),
      "alto-contraste":(
        background-color: var(--ondisabled-var1),
        color: var(--primary-var5)
      )
    );
    @include ThemesProperties($themes);
  }
}
.acordeon_active {
  @apply border-0;
}

/*MI CUENTA--------------------------------------------------------------------------------------------------------*/
.miCuenta-secciones {
  @apply flex flex-col lg:flex-row py-5 border-b border-gris-linea;
}
.miCuenta-cajaInput {
  @apply lg:px-5 pt-5 lg:pt-0 flex flex-row items-center;
}

/*RECURSOS--------------------------------------------------------------------------------------------------------*/
.img-libro {
  @apply w-3/5 sm:w-2/5 md:w-44 mx-auto md:mx-0;
}

/*PERFIL ESTUDIANTE--------------------------------------------------------------------------------------------------------*/

.border-perfil{
  $themes: (
    "clasico":(
      border: none  // border-b border-morado
    ),
    "alto-contraste":(
      border: 2px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);
}

.fondo-verde-claro{
  $themes: (
    "clasico":(
      background-color:#E9FFC6,
      border: none  // border-b border-morado
    ),
    "alto-contraste":(
      background-color: var(--surface-var1),
      border: 2px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);
}

.fondo-verde-imagen{
  $themes: (
    "clasico":(
      background-color:#E9FFC6
    ),
    "alto-contraste":(
      background-color: var(--onprimary-var1)
    )
  );
  @include ThemesProperties($themes);
}

.fondo-rosado{
  $themes: (
    "clasico":(
      background-color:#FFD4D8,
      border: none  // border-b border-morado
    ),
    "alto-contraste":(
      background-color: var(--surface-var1),
      border: 2px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);
}

.fondo-rosado-imagen{
  $themes: (
    "clasico":(
      background-color:#FFD4D8
    ),
    "alto-contraste":(
      background-color: var(--onprimary-var1)
    )
  );
  @include ThemesProperties($themes);
}

.borde-explorar{
  border: 1px solid var(--primary-inv-var2);
  border-bottom: none !important;
}

.borde-buscar{
  border: 1px solid var(--primary-inv-var2);
  border-top: none !important;
}

.boton-desactivado-libros{
  $themes: (
    "clasico":(
      background-color: #E3E3E3,
    ),
    "alto-contraste":(
      background-color: var(--disabled-inv-var1),
      //border: 2px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);
}

.boton-activado-libros{
  $themes: (
    "clasico":(
      background-color: var(--secondary-inv-var1),
    ),
    "alto-contraste":(
      background-color: var(--secondary-inv-var1),
      //border: 2px solid var(--primary-inv-var2)  
    )
  );
  @include ThemesProperties($themes);
}

.boton_hover_desactivado_libros{
  @apply boton-animacion;
  &:hover{
      color: var(--primary-var2) //hover:text-blanco
  }
}

.boton_hover_filtros{
  @apply boton-animacion;
  &:hover{
      background-color: var(--orange-inv-var2) //hover:text-blanco
  }
}


/*LIBROS--------------------------------------------------------------------------------------------------------*/
.columna-filtro{ 
  @apply sticky z-30 top-16 xsm:top-16 sm:top-16 lg:top-0 lg:max-w-270 lg:relative;
}

.columna-actual-seleccion{ 
  @apply sticky  min-w-full z-30 top-28 xsm:top-28  sm:top-28 lg:top-16;
}

.caja-filtros{
  @apply bg-blanco transicion-200 rounded-b-md lg:shadow-3 sticky top-20;
  @apply lg:rounded-md;
}

.nivel-materia{
  @apply alineado-izquierda-center xsm:center md:alineado-izquierda-center  img-contain-left;
}

.nivel-materia-genero{
  @apply col-span-12 md:col-span-4 lg:col-span-6 xl:col-span-4;
}
.__react_component_tooltip{
  z-index: 1000 !important;
}

/*404*/
.dibujo-error {
/*background-image: url("{{assets('/images/error-404.png')}}") ;*/
  top: -120px;
  @apply absolute left-0 w-full h-36 sm:h-56 bg-contain bg-no-repeat bg-bottom ;
}

@media (min-width: 576px) {
  .dibujo-error{
    top: -200px;
  }
}

@media all and (max-height: 700px) and (min-width: 992px) {
  .caja-filtros{
    height: 500px;
  }
}

@media all and (min-width: 1200px) {
  /*Estadisticas - detalle día*/
  .fondoTabla{
    background-color: var(--background-var3);
    $themes:(
      "alto-contraste":(
        border: 1px solid var(--onbackground-var1)
      )
    );
    @include ThemesProperties($themes);
  }
}

