  
  button:disabled{
    @apply font-raleway font-bold shadow border cursor-default;
    color: var(--surface-var1) !important;
    $themes:(
      "clasico":(
        border-color: var(--disabled-inv-var1),
        background: var(--background-var2),
        color: var(--surface-var1)
      ),
      "alto-contraste":(
        border-color: var(--surface-var2),
        background: var(--disabled-inv-var1)
      ),
    );
    @include ThemesProperties($themes);
​
    &:hover{
      $themes:(
        "clasico":(
          background: var(--background-var2),
          color: var(--onsurface-var1)
        ),
        "alto-contraste":(
          border-color: var(--surface-var2),
        ),
      );
      @include ThemesProperties($themes);
    }
  }

/*ESTRUCTURA - TAMAÑOS*/
.boton-animacion{
    @apply transicion-150 hover:transicion-150;
}
.boton-menu{ 
    @apply lg:hidden;
}

/*COLORES DE FONDO*/
.boton-color{
    @apply bg-cover w-full shadow-2 hover:shadow-none transicion-200;
}
.boton-menu-estilo{
    @apply text-30 p-2;

    &:hover{
        color: var(-onsurface-var1) ; //hover:text-gris
    }
    $themes:(
        "calsico":(
            color: var(--surface-var2), // text-gris-linea
        ),
        "alto-contraste":(
            color: var(--primary-inv-var2)
        )
    );
    @include ThemesProperties($themes);

}
.boton-gris-salir{
    @apply  font-medium;
    border: 2px solid;
    background: var(--background-var1); //bg-gris-boton
    border-color: var(--onsurface-var2); //border-gris-linea
    color: var(--onsurface-var1); //text-gris-letra

    &:hover{
        background: var(--surface-var2); //hover:bg-gris-linea 
        color: var(--onprimary-var1); //hover:text-blanco
    }

}
.boton-gris{
    @apply raleway-700  center;
    background: var(--background-var2); //bg-gris-cajas 
    color: var(--onsurface-var1);
    $themes:(
        "alto-contraste":(
            border: 1px solid var(--onsurface-var2)
        )
    );
    @include ThemesProperties($themes);
}
.boton-linea-morado{
    @apply raleway-700 center;
    border: 2px solid var(--primary-inv-var2); //border-morado border-2 
    color: var(--primary-inv-var2); //text-morado
}
.boton-morado{
    @apply  raleway-700 text-18 capitalize;
    background: var(--primary-inv-var2); //bg-morado
    color: var(--onprimary-inv-var1); //text-blanco
}
.boton-morado_desactivado{
    @apply  raleway-700 text-18  capitalize;
    color: var(--primary-var2); //text-morado
    $themes:(
        "clasico":(
            background: var(--primary-var4) //bg-morado-disabled
        ),
        "alto-contraste":(
            background: var(--ondisabled-var1)
        )
    );
    @include ThemesProperties($themes);
}
.boton-linea-amarillo{
    @apply raleway-700 cursor-pointer;
    border-top: 3px solid var(--secondary-inv-var1); //border-t-3 border-amarillo
    border-left: 3px solid var(--secondary-inv-var1); //border-l-3
    border-right: 3px solid var(--secondary-inv-var1);  //border-r-3
}
.boton-amarillo{
    @apply raleway-700 cursor-pointer;
    color: var(--onprimary-inv-var2);
    background: var(--secondary-inv-var1); //bg-amarillo
    color: var(--onsecondary-inv-var1);
}
.boton-amarillo-quicksand{
    @apply quicksand-700 cursor-pointer;
    background: var(--secondary-inv-var1); //bg-amarillo
    color: var(--onsecondary-inv-var1);
}
.boton-rojo{
    @apply raleway-700;
    background: var(--red-inv-var2); //bg-rojo
    color: var(--onred-inv-var1); //text-blanco
}
.boton-rojo-google{
    @apply raleway-700;
    background: var(--red-inv-var2); //bg-rojo-google
    color: var(--onred-inv-var1); //text-blanco

}
.boton-azul-microsoft{
    @apply raleway-700 ;
    background: var(--blue-inv-var1); //bg-azul-microsoft
    color: var(--onblue-inv-var1); //text-blanco
}
.boton-azul{
    @apply raleway-700 text-18 ;
    background: var(--blue-inv-var1); //bg-azul
    color: var(--onblue-inv-var1); //text-blanco
}
.boton-blanco{
    @apply border-l-2 border-2  raleway-700 ;
    background: var(--background-var3); //bg-blanco 
    border: 2px solid var(--onsurface-var2); // border-blanco-boton
    color: var(--onsurface-var1); //text-negro-lista
}
.boton-blanco-ficha{
    @apply  raleway-700;
    background: var(--background-var3); //bg-blanco
    border-left: 2px solid var(--onsurface-var2); //border-l-2 border-blanco-boton
    border-top: 2px solid var(--onsurface-var2); //border-t-2 
    border-right: 2px solid var(--onsurface-var2); //border-r-2 
    color: var(--onsurface-var1) //text-negro-lista

}
.boton-ficha{
    @apply  rounded-xl py-3 px-2 raleway-700 ;
    border: 1px solid; //border 

    $themes:(
        "clasico": (
            border-color: var(--surface-var2), //border-gris
            color: var(--surface-var2) //text-gris
        ),
        "alto-contraste":(
            border-color: var(--onsurface-var2),
            color: var(--onsurface-var2)
        )
    );
    @include ThemesProperties($themes);
}

/*COLORES DE HOVER*/
.boton_hover_escalar{
    @apply hover:transform hover:scale-101;
}
.boton_hover_naranja{
    @apply boton-animacion; 
    &:hover{
        background: var(--orange-var2); // hover:bg-naranja-oscuro
        color: var(--onorange-var1); //hover:text-blanco

        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
.boton_hover_naranja2{
    @apply boton-animacion; 
    &:hover{
        background: var(--orange-var2); // hover:bg-naranja-oscuro
        color: var(--onbackground-var1); 

        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
.boton_hover_amarillo{
    @apply boton-animacion; 
    &:hover{
        background: var(--secondary-var1); // hover:bg-amarillo
        color: var(--onsecondary-var1); //hover:text-negro

        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
.boton_hover_morado{
    &:hover{
        background: var(--primary-var2); // hover:bg-morado
        color: var(--onprimary-var1); //hover:text-blanco

        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
.boton_hover_morado_oscuro{
    @apply  boton-animacion;
    &:hover{
        background: var(--primary-var1); // hover:bg-morado-oscuro
        color: var(--onprimary-var1); //hover:text-blanco

        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
.boton_hover_gris{
    @apply boton-animacion;
    &:hover{
        background: var(--surface-var2); //hover:bg-gris
        color: var(--onprimary-var1) //hover:text-blanco
    }
}

/*BOTON CAMBIAR AVATAR E ICONO*/
.avatar{
    @apply rounded-full h-32 w-32;
}

.avatar2{
    @apply rounded-full h-32 w-32 ;
}
.icono{
    @apply img-80-center border-3 ;
}
.icono_null{
    @apply  border border-dashed  center; 
    background: var(--background-var2); //bg-gris-header
    border-color: var(--onsurface-var1);
    background-repeat: no-repeat; //img-70-center
    background-size: 70%;
    background-position: center;
}

/*Boton otros inicios de sesion*/
.boton-otros-inicios-sesion{
    @apply w-28 h-28  mx-auto block rounded-full relative;
    @apply shadow-3 boton_hover_escalar hover:transicion-200;
    border: 3px solid var(--onprimary-var1); //border-blanco border-3 
}

@variants responsive {
    
    .boton-justo{
        @apply rounded-md px-3.5 py-1 boton-animacion;
    }
    .boton-rounded-top{
        color:var(--onbackground-var1);
        @apply rounded-t-md px-3.5 py-1 boton-animacion;
    }
    .boton-pequenio{
        @apply rounded-md w-40 py-1.5 boton-animacion;
    }
    //para login
    .boton-conoce{
        @apply rounded-md w-48 py-1.5 boton-animacion;
    }
}


.selector-rol{
    $themes:(
        "alto-contraste":(
            background: var(--ondisabled-var1),          
            color: var(--onprimary-inv-var1),
            
    
        ),
        "clasico":(
            background: var(--primary-inv-var4),   
            color: var(--primary-inv-var2),
        ),
    );
    @include ThemesProperties($themes);

  &.active{
    $themes:(
        "alto-contraste":(
            color: var(--onprimary-inv-var1),
            background: var(--primary-inv-var1),
            

        ),
        "clasico":(
            background: var(--primary-inv-var1),   
            color: var(--onprimary-inv-var1),
        ),
    );
    @include ThemesProperties($themes);
}
  }

.skip-link {
    height: 0;
    display: block;
    &:focus{
        @apply raleway-700 cursor-pointer;
        background: var(--secondary-inv-var1); //bg-amarillo
        height: auto;
        text-align: center;
        $themes:(
            "alto-contraste":(
                border: 1px solid var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}