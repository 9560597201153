/*contiene estilos de estructuras o elementos que se usan en diferentes páginas */
.contenido{
    @apply px-5 lg:px-14 pt-4 lg:pt-5;
}

/*CRUD*/
.crud-seccion{
    @apply flex flex-col lg:flex-row py-5 border-b;
    border-color: var(--onsurface-var2); //border-gris-linea
}
.crud-contenido{
    @apply  pt-2 lg:pt-0;
}
.crud-confirmar{
    @apply flex flex-col md:flex-row items-end md:items-center md:justify-end my-5;
}

/*Caja Elemento Nuevo-Agregar*/
.caja-agregar{
    @apply cursor-pointer border border-dashed rounded-md center relative;
    @apply hover:transform hover:scale-101 transicion-300;

    $themes: (
        "clasico": (
            background: rgba(236, 236, 236, 0.49), //bg-gris-alfa
            border-color: var(--surface-var2) //border-gris
        ),
        "alto-contraste": (
            background: #ffff0034,
            border-color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
.caja-agregar:before{
    content:'';
    @apply w-full h-full absolute inset-0 transform scale-0 transicion-200 z-0;

    $themes: (
        "clasico": (
            background: rgba(236, 236, 236, 0.49), //bg-gris-alfa
        ),
        "alto-contraste": (
            background: #ffff0000,
        )
    );
    @include ThemesProperties($themes);
}
.caja-agregar:hover:before{
    @apply border-0 rounded-md transform scale-100 transicion-200 ;
}

/*Caja Elemento Creado*/
.caja-creado{
    background: var(--background-var2);
    $themes: (
        "clasico": (
            border: none,
             //bg-gris-alfa
        ),
        "alto-contraste": (
            border: 2px solid var(--onprimary-var1),
        )
    );
    @include ThemesProperties($themes);
    @apply rounded-md shadow-1;
}
.caja-creado-titulo{
    $themes: (
        "clasico": (
            border: none,
             //bg-gris-alfa
        ),
        "alto-contraste": (
            border-bottom: 2px solid var(--onprimary-var1),
        )
    );
    @include ThemesProperties($themes);
    @apply rounded-t-md p-2.5;
}
.caja-creado-titulo-img{
  @apply -left-8 sm:-left-4;
    @apply  h-28 w-28 sm:h-24 sm:w-24 lg:h-20 lg:w-20 xl:h-24 xl:w-24;
    @apply bg-blanco absolute border-4 border-gris-tabla rounded-full;
}

.caja-creado-titulo-img-2{
  @apply -right-8 sm:-right-4;
    @apply  h-28 w-28 sm:h-24 sm:w-24 lg:h-20 lg:w-20 xl:h-40 xl:w-40;
    @apply bg-blanco absolute border-4 border-gris-tabla rounded-full;
}

.caja-creado-contenido-fila{
    border-bottom: 2px solid var(--onprimary-var1);
    @apply flex justify-between py-3;
}
.caja-creado-icono{
    @apply text-24 mr-2 xl:mr-5 text-gris-textos;
}

/*Caja cursos verificar*/
.caja-verificar-fila{
    border-color: var(--background-var3);
    @apply border-b py-3;
}

.descripcion-tool{
    height: 0;
    display: block;
    opacity:0;
}

/*MULTISELECTOR*/
.multiselector{
    z-index: var(--zindex-multiSelector);
}