.visor-header{
  $themes:(
    "alto-contraste":(
      background-color:var(--background-var1),
      color:var(--onbackground-var1)
    ),
    "clasico":(
      background-color:#1e1e1e,
      color:#fff
    )
  );
  @include ThemesProperties($themes);

  @apply quicksand-400 shadow-visor absolute w-full top-0 z-1000;
}

.descubre-container{
  $themes:(
    "alto-contraste":(
      background-color:var(--background-var1),
      color:var(--onbackground-var1)
    ),
    "clasico":(
      background-color:#4D4D4D,
      // color:#fff
    )
  );
  @include ThemesProperties($themes);
  @apply overflow-y-auto h-full w-10/12 max-w-450 absolute z-1000 top-0 -right-full transicion-300 img-cover-center px-3 md:px-8;
}

.titulo-descubre{
  $themes:(
    "alto-contraste":(
      color:var(--onbackground-var1)
    ),
    "clasico":(
      color:#fff,
      border-color: #fff
      // color:#fff
    )
  );
  @include ThemesProperties($themes);
  @apply my-3 quicksand-700 text-18 border-b-2;
}
.title-controls{
  @apply absolute right-5 top-5 z-30;
}
.font-size-modal{
  $themes:(
    "alto-contraste":(
      background-color:var(--background-var1),
      color:var(--onbackground-var1)
    ),
    "clasico":(
      background-color:#1e1e1e,
      color:#fff
    )
  );
  @include ThemesProperties($themes);
  @apply absolute right-1 top-20 z-30 rounded-tl-md rounded-bl-md ;
}

.footer-copyright {
  $themes:(
    "alto-contraste":(
      background-color:var(--background-var1),
      color:var(--onbackground-var1)
    ),
    "clasico":(
      background-color:#1e1e1e,
      color:#fff
    )
  );
  @include ThemesProperties($themes);
  @apply  absolute bottom-0 w-full  text-13 z-30;
}

.card{
  background-color: var(--background-var3);
  @apply my-4 alineado-verticalmente justify-between px-5 py-2 rounded-lg raleway-400;
}


.mb-bookvisor-container {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.mb-bookvisor {
  height: calc(100% - 96px);
  margin-top: 96px;
  width: 100%;
  overflow: hidden;
}
.mb-bookvisor button:first-child {
  border-radius: 0.375rem !important;
  background-color: #000 !important;
}
.mb-bookvisor button:first-child span {
  background-color: var(--background-var3)!important;
}
.copy-terms {
  display: none;
}
.close-descubre {
  position: absolute;
  top: 0;
  right: 15px;
}
.iframeClass {
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
}
/* .font-size-modal .titulo-texto {
  position: relative;
  margin: 0;
  padding: 6px;
  text-align: center;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px 3px 0 0;
}
.font-size-modal .titulo-texto h3 {
  font-size: 22px;
  font-weight: 300;
}
.font-size-modal .titulo-texto i {
  position: absolute;
  font-size: 1.3em;
  right: 10px;
  cursor: pointer;
} */
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  $themes:(
    "alto-contraste":(
      background-color:var(--background-var1)
    ),
    "clasico":(
      background: rgba(255, 255, 255, 0.8)
    )
  );
  @include ThemesProperties($themes);
}
.fullscreen-enabled .mb-bookvisor {
  margin: 0px !important;
  height: 100% !important;
  width: 100% !important;
}
@media  (min-width: 526px) {
  .mb-bookvisor{
      margin-top:72px;
  }
}

@media only screen and (min-width: 768px) {
  .copy-terms {
      display: inline-block;
 }
}

