.modal__overlay {
  @apply fixed inset-0 flex justify-center items-center bg-negro-alfa z-1000;
}
.modal__container_avatar {
  @apply  py-8 rounded overflow-y-auto box-border;
  background: var(--background-var3); //bg-blanco
  $themes:(
    "alto-contraste":(
       border: 1px solid var(--onprimary-var1)
    )
  );
  @include ThemesProperties($themes);
}
.modal__container_error {
    @apply  w-11/12 lg:w-1/2 max-w-572 p-6 rounded overflow-y-auto box-border relative;
    background: var(--primary-var5); //bg-morado-claro
    $themes:(
      "alto-contraste":(
        border: 1px solid var(--onprimary-var1)
      )
    );
    @include ThemesProperties($themes);
}
.style-icon-crerrar{
  color: var(--onsecondary-var1);
  &:hover{
    color: var(--primary-inv-var2);
  }
}
/*AVATAR*/
.avatar-active{
  border: 8px solid var(--primary-inv-var2) !important;
}
.avatar-container {    
  @apply flex items-center justify-between ;
  background: var(--background-var1); //bg-gris-container
  $themes:(
    "alto-contraste":(
      border: 1px solid var(--onprimary-var1)
    )
  );
  @include ThemesProperties($themes);
}
.avatar-container .swiper-container {
  position: initial;
  padding: 0 !important;
}
/*.avatar-container .swiper-slide{
width:130px !important;
height:130px !important;
@apply border-3 border-blanco rounded-full mb-3 cursor-pointer;
}*/

.avatar-container .swiper-avatar{
width:130px !important;
height:130px !important;
@apply border-3 border-blanco rounded-full mb-3 cursor-pointer;
}

.paginacion-container .swiper-slide{
width:30px !important;
height:30px !important;
@apply bg-morado justify-around;
}
.micromodal-slide {
  @apply hidden; 
}
.micromodal-slide.is-open {
  @apply block; 
}
.micromodal-slide-eliminar {
  @apply max-w-screen-md rounded-md;
}
.micromodal-slide[aria-hidden="false"] .modal__overlay {
  @apply animate-fadeIn;
}
.micromodal-slide[aria-hidden="true"] .modal__overlay {
  @apply animate-fadeOut;
}
.micromodal-slide[aria-hidden="false"] .modal__container_avatar,
.micromodal-slide[aria-hidden="false"] .modal__container_error
{
  @apply animate-slideIn;
}
.micromodal-slide[aria-hidden="true"] .modal__container_avatar, 
.micromodal-slide[aria-hidden="true"] .modal__container_error 
{
  @apply animate-slideOut;
}
.micromodal-slide .modal__container_avatar,
.micromodal-slide .modal__overlay {
will-change: transform;
}
.swiper-wrapper{
@apply w-full;
}
.avatar-container .swiper-button-prev,
.avatar-container .swiper-button-next {
 /* top: 64% !important;*/
background-color: var(--primary-inv-var2);
  @apply  px-5;
}
.avatar-container .swiper-button-prev::after, 
.avatar-container .swiper-button-next::after  {
  color: var(--onprimary-inv-var1);
  font-family: icomoon !important;
  font-size: 30px !important;
}
.avatar-container .swiper-button-prev::after{
content: "\e915" !important;
}
.avatar-container .swiper-button-next::after{
content: "\e916" !important;
}
/*
.swiper-pagination-bullets {
bottom: 24% !important;
}*/
.swiper-pagination-bullet-active {
  background-color: var(--primary-inv-var2)!important;
}

.text-resaltado{
  color: var(--primary-var2);
  $themes:(
    "alto-contraste":(
        background-color: var(--onbackground-var1)
    )
);
@include ThemesProperties($themes);
}

.bg-img{
  $themes:(
    "alto-contraste":(
        background-color: var(--onbackground-var1)
    )
);
@include ThemesProperties($themes);
}
