  * {
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *:focus, *:focus-visible, *:focus-within{
    outline: none;
}

  button{
    border: none;
    outline: none;
    background: transparent;
  }

  main{

  }


  body{
    &.fontSize16 {
      font-size: 16px;
    }
    &.fontSize18 {
      font-size: 18px;
    }
    &.fontSize20 {
      font-size: 20px;
    }
  
    &.fontSize22 {
      font-size: 22px;
    }
  
    &.fontSize24 {
      font-size: 24px;
    }
  
    &.fontSize26 {
      font-size: 26px;
    }
  
    &.fontSize28 {
      font-size: 28px;
    }
  
    &.fontSize30 {
      font-size: 30px;
    }
  
    &.fontSize32 {
      font-size: 32px;
    }

    background-color: var(--background-var3);
    // font-family: var(--primary-font);
    @apply bg-auto bg-center bg-fixed;
  }
  header{
    z-index: var(--zindex-header)!important;
    @apply px-5 lg:px-10 py-2.5 w-full;
  }
  hr{
    border-color: var(--onsurface-var2);
    @apply border-solid;
  }

  img{
    image-rendering: -moz-crisp-edges; 
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast; 
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }


  .container {
    max-width: 1440px;
    margin: auto;
  }

  .container-login {
    max-width: 1140px;
    margin: auto;
  }

  .text-indent {
    @apply overflow-hidden;
    text-indent: -1000px;
  }

  .favorito{
    -webkit-text-stroke: 2.5px var(--onbackground-var1);
    text-shadow: 2px 1px 3px var(--onbackground-var1);
    color: var(--background-var3);
  }

  .favorito-seleccionado{
    -webkit-text-stroke: 2.5px #fff;
    text-shadow: 2px 2px 3px #262626;
    @apply text-rojo;
  }

  /*scroll*/
  .scroll {
    @apply overflow-y-auto;
  }
  .scroll::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-var3);
  }
  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--background-var2);
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary-inv-var2);
  }

  /* login */
  .pl-home {
    background: var(--primary-var2)  center/ cover fixed;
    background-blend-mode: multiply;
  }

  .btn-estudiante {
    background-size: 70px 70px;
    @apply bg-contain bg-left bg-no-repeat;
  }

  .btn-docente {
    background-size: 70px 70px;
    @apply bg-contain bg-left bg-no-repeat;
  }

  /*estadisticas*/
  .tooltipGrafica{
    @apply p-3 relative xl:block  hover:shadow-4 cursor-pointer  ;
    border-bottom: 1px solid var(--onsurface-var1); //border-b border-gris-linea
  }

  .pestana::after{
    content:'';
    position: absolute;
    top: calc((100% /2) - 16px);
    transform-origin: 50%;
    border-top: 14px solid transparent;
    border-bottom:  14px solid transparent;
    right:  -18px;
    @apply hidden lg:block;

    $themes:(
      "clasico":(
        border-left: 18px solid #FFD100
      ),
      "alto-contraste":(
        border-left: 18px solid var(--onbackground-var1)
      ),
    );
    @include ThemesProperties($themes);
  }

  .rmdp-container{
    width:100% !important;
  }
  .rmdp-container input{
    width: 100% !important;
  }

  .modal__overlay{
    z-index: var(--zindex-notificaciones)!important;
  }