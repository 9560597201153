/*------------------------------------------------------------------------------ESTRUCTURAS*/
.logo-makemake{
    @apply w-36 h-24 mx-auto bg-contain bg-no-repeat bg-center; 
}
.logo-makina{
    @apply w-28 mx-auto;
}
/*------------------------------------------------------------------------------ESTILO/APARIENCIA*/
.footer-estilo{
    @apply bg-negro raleway-400 text-17 text-blanco-claro;
}
.paises{
    @apply boton-animacion; 
    &:hover{
        $themes:(
            "clasico":(
                color: var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
    @apply text-12 uppercase font-semibold py-1 cursor-pointer;
}
.paises_active{
    @apply paises;
    @apply boton-animacion; 
    &:hover{
        $themes:(
            "clasico":(
                color: var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
    border-bottom: 1px solid var(--onsurface-var2) !important;
    color: var(--onprimary-var1) !important;
    @apply border-b ;
}
.footer-email{
  height: 30px;
  @apply text-15 px-2 rounded text-negro outline-none;
}
.logo-innpulsa{
    @apply w-36 md:w-24 md:h-8;
}
.logo-col-prod{
    @apply w-36 h-20 md:w-24 md:h-11;
}
.logo-eco-naranja{
    @apply w-36 h-6;
}
.terminos-y-condiciones{
    @apply text-center xl:text-right  text-14 mt-5 xl:mt-0; 
}
.boton-morado-footer{
    padding-bottom: 0 !important;
    @apply text-16 font-normal h-auto cursor-pointer ; 
}

.icon-redes-contraste{
    @apply boton-animacion; 
    &:hover{
        $themes:(
            "clasico":(
                color: var(--onprimary-var1)
            )
        );
        @include ThemesProperties($themes)
    }
}
