/*Capas de estilo de tailwinds*/
@tailwind base; 
@tailwind components; 
@tailwind utilities; 

@import "./abstracts/abstracts.scss";
@import "./pages/pages.scss";
@import "./components/components.scss";
@import "./utilities/utitiles.scss";
@import "./themes/themes.scss";

