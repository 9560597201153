$secondary-font : 'Quicksand';

$grid-columns:      12;
$grid-gutter-width: 30px;
$font-size-base : 20;
$btn-font-family: $secondary-font;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 526px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 640px,//540
  md: 740px,//720
  lg: 960px,
  xl: 1140px
);

:root{
  //COLORES
  --background-var1: #D8D8D8;
  --background-var2: #F1F1F1;
  --background-var3:#FFFFFF;
  --onbackground-var1:#000000;
  --onbackground-var2: #262626;
  --surface-var1: #464545;
  --surface-var2:#7B7B7B;
  --surface-var3: #B3B3B3;
  --surface-var4: #f2f2ff;
  --onsurface-var1: #4A4A4A;
  --onsurface-var2:#BDBDBD;
  --primary-var1: #5050A1;
  --primary-var2: #6666CC;
  --primary-var3: #8A1EF6;
  --primary-var4: #BBBBEA;
  --primary-var5: #F1F1FF;
  --onprimary-var1: #FFF;
  --onprimary-var2: #000;
  --primary-inv-var1: #5050A1;
  --primary-inv-var2: #6666CC;
  --primary-inv-var3: #8A1EF6;
  --primary-inv-var4: #BBBBEA;
  --primary-inv-var5: #F1F1FF;
  --onprimary-inv-var1: #FFF;
  --onprimary-inv-var2: #000;
  --secondary-var1: #FFD100;
  --onsecondary-var1: #000;
  --secondary-inv-var1: #FFD100;
  --onsecondary-inv-var1: #000;
  --red-var1: #C53030;
  --red-var2: #FF3548;
  --onred-var1: #FFF;
  --red-inv-var1: #C53030;
  --red-inv-var2: #FF3548;
  --onred-inv-var1: #FFF;
  --green-var1: #48BB78;
  --green-var2: #99D438;
  --ongreen-var1: #FFF;
  --green-inv-var1: #48BB78;
  --green-inv-var2: #99D438;
  --ongreen-inv-var1: #FFF;
  --blue-var1:#4A90E2;
  --blue-var2: #E0ECFA;
  --onblue-var1: #FFF;
  --blue-inv-var1:#4A90E2;
  --blue-inv-var2: #E0ECFA;
  --onblue-inv-var1: #FFF;
  --orange-var1: #F05922;
  --orange-var2:#F5A623;
  --onorange-var1: #FFF;
  --orange-inv-var1: #F05922;
  --orange-inv-var2:#F5A623;
  --onorange-inv-var1: #FFF;
  --disabled-var1: #666;
  --ondisabled-var1: #fff;
  --disabled-inv-var1: #666;
  --ondisabled-inv-var1: #fff;
  // FUENTES
  --primary-font:'Raleway';
  --secondary-font:'Quicksand'; 
  //Z INDEX
  --zindex-header: 1001;
  --zindex-modales: 1002;
  --zindex-multiSelector: 1002;
  --zindex-notificaciones: 1003;
}








