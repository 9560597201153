/*------------------------------------------------------------------------------ESTRUCTURAS*/
.menu-option {
  @apply font-quicksand text-20 pl-3;
}
.menu-fondo-cerrar {
  top: 0;
  @apply absolute h-full w-screen z-30 lg:hidden;
}
.columna-menu {
  @apply fixed lg:col-span-3 lg:sticky xl:col-span-2;
}
/*------------------------------------------------------------------------------ESTILO/APARIENCIA*/
.menu-lateral-estilo {
  min-width: 250px;
  padding-left: 1.5rem;
  padding-right: 1rem;
  background: var(--background-var2); //bg-gris-menu
  @apply lg:min-w-full py-6 h-full relative rounded-tr-3xl;
  
  $themes:(
    "alto-contraste":(
      border-right: 1px solid var(--onbackground-var1)
    )
  );
  @include ThemesProperties($themes);

}
.menu-contenido::after {
  content: " ";
  left: -50vw;
  top: 0;
  width: 50vw;
  background: var(--background-var2); //bg-gris-menu
  @apply  h-full absolute;
}
.menu-option_candado {
  @apply menu-option  font-normal;
  @apply hover:border-0;

  $themes:(
    "clasico":(
      color: var(--surface-var2) //text-gris-linea
    ),
    "alto-contraste":(
      color: var(--onsurface-var2)
    )
  );
  @include ThemesProperties($themes);
}
.menu-option_desactivado {
  @apply menu-option  font-normal;
  @apply hover:transition-all hover:duration-200;

  &:hover{
    border-left: 7px solid var(--primary-inv-var2); //hover:border-l-7 hover:border-morado
  }

  &:focus-visible{
    border-left: 7px solid var(--primary-inv-var2); 
  }

  $themes:(
    "clasico":(
      color: var(--surface-var2) //text-gris
    ),
    "alto-contraste":(
      color: var(--ondisabled-var1)
    )
  );
  @include ThemesProperties($themes);
}
.menu-option_activado {
  @apply menu-option  font-bold;
  @apply hover:transition-all hover:duration-200;
  color: var(--onbackground-var1);
  border-left: 7px solid var(--primary-inv-var2); //border-l-7 border-morado
}
.header-elementos {
  @apply pl-3 mb-5 font-quicksand text-20 text-left;
  $themes:(
    "clasico":(
      color: var(--surface-var2) //text-gris
    ),
    "alto-contraste":(
      color: var(--onsurface-var2)
    )
  );
  @include ThemesProperties($themes);
}
.header-elementos_hover {
  @apply hover:transition-all hover:duration-200;
  &:hover{
    border-left: 7px solid var(--primary-inv-var2); //hover:border-l-7 hover:border-morado 
  }
}