.text{
    &-12{
        font-size: pxToEm(12)!important;
    }
    &-13{
        font-size: pxToEm(13.6)!important;
    }
    &-14{
        font-size: pxToEm(14)!important;
    }
    &-15{
        font-size: pxToEm(15)!important;
    }
    &-16{
        font-size: pxToEm(16)!important;
    }
    &-17{
        font-size: pxToEm(17)!important;
    }
    &-17_6{
        font-size: pxToEm(17.6)!important;
    }
    &-18{
        font-size: pxToEm(18)!important;
    }
    &-20{
        font-size: pxToEm(20)!important;
    }
    &-22{
        font-size: pxToEm(22)!important;
    }
    &-24{
        font-size: pxToEm(24)!important;
    }
    &-26{
        font-size: pxToEm(26)!important;
    }
    &-27{
        font-size: pxToEm(27)!important;
    }
    &-28{
        font-size: pxToEm(28)!important;
    }
    &-30{
        font-size: pxToEm(30)!important;
    }
    &-32{
        font-size: pxToEm(32)!important;
    }
    &-34{
        font-size: pxToEm(34)!important;
    }
    &-38{
        font-size: pxToEm(38)!important;
    }
    &-40{
        font-size: pxToEm(40)!important;
    }
    &-44{
        font-size: pxToEm(44)!important;
    }
    &-50{
        font-size: pxToEm(50)!important;
    }
    &-80{
        font-size: pxToEm(80)!important;
    }
    &-100{
        font-size: pxToEm(100)!important;
    }
}

/*TITULOS PRINCIPALES: son los titulos que cuentan con una linea inferior. */
.titulos-principales{ 
    @apply border-b-2 border-solid; 
    @apply text-28 quicksand-700; 
    color: var(--onbackground-var2); //text-negro-claro
   
    $themes:(
        "clasico":(
            border-color: var(--surface-var2) //border-gris-linea
        ),
        "alto-contraste": (
            border-color: var(--onsurface-var1) //border-gris-linea
        )
    );
    @include ThemesProperties($themes);
}
.titulos-principales2{ 
    @apply text-28 quicksand-700; 
    color: var(--onbackground-var2); //text-negro-claro
}
/*texto bajo los titulos principales*/
.informacion-principal{
    @apply quicksand-700 text-18  py-2 xl:py-1;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
/*miga de pan o ruta*/
.ruta{
    @apply  flex-wrap flex  w-fit py-2 px-5 lg:px-14 mt-8 lg:mt-11;
    @apply raleway-700 text-16 rounded-r-md;
    background: var(--background-var2);
    color: var(--onbackground-var1);
    $themes: (
        "alto-contraste":(
            border: 1px solid var(--onbackground-var1)
        ),
    );
    @include ThemesProperties($themes);

    .rutaAnterior{
        cursor: pointer;
        &:hover{
            color: var(--primary-inv-var1) !important;
        }
    }
}
/*raleway 700 14px #FFFFFF*/
.r7-16-blanco{
    @apply quicksand-700 text-16;
    color: var(--onprimary-var) //text-blanco
}
/*raleway 700 18px #FFFFFF*/
.r7-18-blanco{
    @apply quicksand-700 text-18;
    color: var(--onorange-inv-var1) //text-blanco
}
/*raleway 700 18px gris97*/
.r7-18-gris97{
    @apply raleway-700 text-18;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-linea
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
/*raleway 700 16px */
.r7-16{
    @apply raleway-700 text-16;
}
/*raleway 700 16px #262626*/
.r7-16-negro26{
    @apply raleway-700 text-16;
    color: var(--onbackground-var2) //text-negro-claro
}

/*raleway 700 20px #262626*/
.r7-20-negro26{
    @apply raleway-700 text-20;
    color: var(--onbackground-var2) //text-negro-claro
}

/*raleway 700 20px #FF3548*/
.r7-20-rojo{
    @apply raleway-700 text-20;
    color: var(--red-inv-var2); //text-rojo
}
/*raleway 700 20px #262626*/
.r7-13-negro26{
    @apply raleway-700 text-13;
    color: var(--onbackground-var2) //text-negro-claro
}
/*raleway 700 13px rojo*/
.r7-13-rojo{
    @apply raleway-700 text-13;
    color: var(--red-inv-var2); //text-rojo
}
/*raleway 700 30px*/
.r7-30{
    @apply raleway-700 text-30;
}
/*raleway 700 13px #FF3548*/
.r7-rojo-alerta{
    @apply raleway-700;
    color: var(--red-inv-var1) //text-rojo-alerta
}
/*raleway 700 #6666CC*/
.r7-morado{
    @apply raleway-700;
    color: var(--primary-inv-var2); //text-morado
}
/*raleway 700 13px #FF3548*/
.r7-18-negro{
    @apply raleway-700 text-18;
    color: var(--onbackground-var2) //text-negro-claro
}
/*raleway 700 13px #FF3548*/
.r7-18-rojo{
    @apply raleway-700 text-13;
    color: var(--red-inv-var2); //text-rojo
}
/*raleway 500 16px */
.r5-16{
    @apply raleway-500 text-16;
}
/*raleway 500 13px #262626*/
.r5-13-negro26{
    @apply raleway-500 text-16;
    color: var(--onbackground-var2) //text-negro-claro
}
/*raleway 500 18px #262626*/
.r5-18-negro26{
    @apply raleway-500 text-18;
    color: var(--onbackground-var2) //text-negro-claro
}
/*raleway 500 20px #262626*/
.r5-20-negro26{
    @apply raleway-500 text-20;
    color: var(--onbackground-var2) //text-negro-claro
}
/*raleway 500 14px #4A4A4A*/
.r5-14-negro4A{
    @apply raleway-500 text-14;
    color: var(--onsurface-var1) //text-negro-lista
}
.r5-14-negro4D{
    @apply raleway-500 text-14 ;
    color: var(--onsurface-var1) //text-negro-ficha
}
.r5-16-blanco{
    @apply raleway-500 text-16;
    color: var(--onprimary-var1) //text-blanco
}
.r5-18{
    @apply raleway-500 text-18;
}
.r5-24{
    @apply raleway-500 text-24;
}
/*raleway 400 14px #6A6969*/
.r4-14-gris6a{
    @apply raleway-400 text-14;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
.texto-verificar{
    @apply raleway-400 text-14;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
  
/*raleway 400 14px #6A6969*/
.r4-16-negro4A{
    @apply raleway-400 text-16;
    color: var(--onsurface-var1) //text-negro-lista
}
/*quicksand 400 16px #262626 */
.q4-16-negro26{
    @apply quicksand-400 text-16;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 400 18px #262626 */
.q4-18-negro26{
    @apply quicksand-400 text-18;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 18px morado */
.q7-18-morado{
    @apply quicksand-700 text-18;
    color: var(--primary-inv-var2); //text-morado
}
/*quicksand 700 18px verde */
.q7-18-verde{
    @apply quicksand-700 text-18;
    color: var(--green-inv-var2); //text-verde
}
/*quicksand 400 20px */
.q4-20{
    @apply quicksand-400 text-20;
}
/*quicksand 500 morado*/
.q5-morado{
    @apply quicksand-500;
    color: var(--primary-inv-var2); //text-morado
}
/*quicksand 500 14px */
.q5-14{
    @apply quicksand-500 text-14;
}
/*quicksand 500 18px #262626 */
.q5-18-negro26{
    @apply quicksand-500 text-18;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 500 26 morado*/
.q7-20-rojo{
    @apply quicksand-700 text-20;
    color: var(--red-inv-var2); //text-rojo
}
/*quicksand 500 26 morado*/
.q5-26-rojo{
    @apply quicksand-500 text-26;
    color: var(--red-inv-var2); //text-rojo
}
/*quicksand 500 32*/
.q5-32{
    @apply quicksand-500 text-32;
}
/*quicksand 600 morado*/
.q7-20-morado{
    @apply quicksand-700 text-20;
    color: var(--primary-inv-var2); //text-morado
}
/*quicksand 700 morado*/
.q7-morado{
    @apply quicksand-700;
    color: var(--primary-inv-var2); //text-morado
}
/*quicksand 700 18px*/
.q7-18{
    @apply quicksand-700 text-18;
}
.q7-16-negro28{
    @apply quicksand-700 text-28;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 20px*/
.q7-20{
    @apply quicksand-700 text-20 ;
}
/*quicksand 700 20px #262626*/
.q7-20-negro26{
    @apply quicksand-700 text-20;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 18px #6A6969*/
.q7-18-gris6A{
    @apply quicksand-700 text-18;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
/*quicksand 700 14px #262626*/
.q7-14-negro26{
    @apply quicksand-700 text-17;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 14px #262626*/
.q7-16-gris97{
    @apply quicksand-700 text-16;
    color: var(--surface-var2) //text-gris-linea
}
/*quicksand 700 16px #262626 */
.q7-16-negro26{
    @apply quicksand-700 text-16;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 
#6A6969 */
.q7-gris6A{
    @apply quicksand-700;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
/*quicksand 700 16px #ffffff */
.q7-16-blanco{
    @apply quicksand-700 text-16;
    color: var(--onprimary-var1) //text-blanco
}
/*quicksand 700 18px #262626 */
.q7-18-negro26{
    @apply quicksand-700 text-18;
    color: var(--onbackground-var2) //text-negro-claro
}
/*quicksand 700 18px */
.q7-18{
    @apply quicksand-700 text-18;
}

/*quicksand 700 24px */
.q7-24{
    @apply quicksand-700 text-24;
}
/*quicksand 700 28px */
.q7-28{
    @apply quicksand-700 text-28;
}
/*quicksand 700 18px negro4D */
.q7-18-negro4d{
    @apply quicksand-700 text-18;
    color: var(--onsurface-var1) //text-negro-ficha
}
/*quicksand 700 28px negro4D */
.q7-28-negro4d{
    @apply quicksand-700 text-28;
    color: var(--onsurface-var1) //text-negro-ficha
}
/*quicksand 700 24px #B3B3B3*/
.q7-24-grisB3{
    @apply quicksand-700 text-24 ;
    $themes: (
        "clasico":(
            color: var(--surface-var3) //text-gris-numero
        ),
        "alto-contraste": (
            color: var(--onsurface-var2)
        )
    );
    @include ThemesProperties($themes);
}
/*quicksand 700 30px #B3B3B3*/
.q7-30-grisB3{
    @apply quicksand-700 text-30;
    $themes: (
        "clasico":(
            color: var(--surface-var3) //text-gris-numero
        ),
        "alto-contraste": (
            color: var(--onsurface-var2)
        )
    );
    @include ThemesProperties($themes);
}
/*quicksand 700 32px negro4D */
.q7-32-negro4d{
    @apply quicksand-700 text-32;
    color: var(--onsurface-var1) //text-negro-ficha
}
/*quicksand 700 40px */
.q7-34{
    @apply quicksand-700 text-34;
}
.q7-40{
    @apply quicksand-700 text-40;
}
/*quicksand 700 20px #333333*/
.q7-20-negro33{
    @apply quicksand-700 text-20;
    color: var(--onsurface-var1) //text-negro-textos
}
/*quicksand 700 20px #FFFFFF*/
.q7-20-blanco{
    @apply quicksand-700 text-20;
    color: var(--onprimary-var1) //text-blanco
}
.asterisco{
    @apply q7-18 leading-none;
    color: var(--red-inv-var2); //text-rojo
    
}
/*Eventos o llamadas a la acción*/
.evento_rojo{
    @apply raleway-700 text-16 underline;
    color: var(--red-inv-var2); //text-rojo
}
/*Eventos o llamadas a la acción*/
.evento_blanco{
    @apply raleway-700 text-16 underline;
    color: var(--onprimary-var1) //text-blanco
}
.evento_desactivado{
    @apply raleway-700 text-16 underline;
    $themes:(
        "clasico":(
            color: var(--surface-var2) //text-gris-textos
        ),
        "alto-contraste": (
            color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
}
/*texto responsive centrado*/
.texto-centrado-md{
    @apply text-center md:text-left;
}
@variants responsive {
    /*Textos en una sola linea*/
    .una-linea-texto{
        @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
    }
}
