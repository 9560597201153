/*INPUT*/
.input_disabled{
    $themes:(
        "alto-contraste":(
            background:var(--disabled-inv-var1),
            color: var(--ondisabled-inv-var1)
        ),
        "clasico":(
            background:#EFEEEE,
            color: #6A6969
        ),
    );
    @include ThemesProperties($themes);
    @apply font-quicksand text-16;
}
// .input-password_focus{
//     @apply focus:bg-blanco focus:ring-2 focus:ring-morado  focus:text-negro focus:outline-none focus:font-medium focus:border-0;
// }
.input_error{
    @apply border-2;
    border-color: var(--red-inv-var1); //border-rojo-alerta
}
.input-lectura{
    border: 2px solid var(--primary-inv-var2);
    @apply w-full xl:w-451p rounded-md p-2;
}
.alert_error{
    @apply text-12 quicksand-700;
    color: var(--red-inv-var1); //text-rojo-alerta
}

/*SELECT*/
.selector{
    @apply relative;
}
.selector-elegido{
    color: var(--primary-inv-var2);
    background-color:rgba(102, 102, 204, 0.26087);    
    $themes:(
        "alto-contraste":(
            background-color:var(--background-var1),
            border: solid 2px var(--onbackground-var1)
        ),
    );
    @include ThemesProperties($themes);
    @apply h-9 cursor-pointer text-18 quicksand-700  w-full flex justify-between items-center px-3;
}
.selector-elegido-input{
    @apply w-full h-9 flex justify-between items-center px-3;
    @apply font-quicksand text-16;
    border: 1px solid;
    background: var(--background-var3); //bg-blanco 
    color: var(--onbackground-var1); //text-negro
    $themes:(
        "clasico":(
            border-color: var(--surface-var2) //border-gris-linea
        ),
        "alto-contraste": (
            border-color: var(--onsurface-var1)
        )
    );
    @include ThemesProperties($themes);
    
    &:focus-visible{
        outline: solid 2px var(--primary-inv-var2)
    }
} 
.selector-elegido-input-error{
    background-color: var(--background-var3);
    color: var(--onbackground-var1);
    border: 1px solid;
        $themes:(
        "clasico":(
            border-color: var(--red-inv-var1) //border-gris-linea
        ),
        "alto-contraste": (
            border-color: var(--red-inv-var1)
        )
    );
    @include ThemesProperties($themes);
    @apply  w-full h-9 flex justify-between items-center px-3;
    @apply  font-quicksand text-16 border-2;
}
.selector-opciones{
    background-color:var(--background-var3);
    $themes:(
        "alto-contraste":(
            border: solid 2px var(--onbackground-var1),
            border-top:none
        ),
    );
    @include ThemesProperties($themes);
    @apply shadow-1 absolute w-full rounded-b-md overflow-auto transicion-300; 
    & > .list{
        > .item{
            color: var(--onbackground-var1);         
            &:hover{
                background-color: var(--primary-var5);         
            }
        }
        > .item-title{
            color: var(--primary-var2);        
        }
    }
}

/*RADIO BUTTON*/
.radio-boton{
    @apply cursor-pointer relative block p-3 pl-10 quicksand-400 text-18;
    color: var(--onbackground-var2); //text-negro-claro
}
.radio{
    @apply w-7 h-7 rounded-full border-2 absolute left-0;
    border-color: var(--primary-inv-var2); //border-morado 
}
.radio:after{
    content:'';
    @apply w-4 h-4 block absolute top-1 left-1;
    @apply rounded-full transicion-200 transform scale-0; 
}

/*TEXTAREA*/
.textarea{
    @apply px-3 py-2 font-quicksand resize-none;
    color: var(--onsurface-var1); //text-gris-textos
}
.textarea-error{
    border: 2px solid var(--red-inv-var1)
}

/*INPUTFILE*/
.inputFile{
    @apply xl:w-451p h-36 border border-dashed center p-10;
    border-color: var(--onsurface-var1) //border-gris
}
.inputFile-incorrecto{
    @apply border-2 transform scale-101 transicion-300;
    border-color : var(--red-inv-var1) !important; //border-rojo-alerta
}
.inputFile-correcto{
    @apply border-2 transform  scale-101 transicion-300;
    border-color: var(--green-inv-var1); //border-verde-alerta
}

/*elemento seleccionado INPUTTEXTSELECT*/
.elementoSeleccionado{
    @apply bg-morado alineado-verticalmente justify-between py-1 px-3 rounded-full mb-3 transicion-300 text-blanco;
}