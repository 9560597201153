
input{
    padding: 0 40px 0 12px  !important;
    @apply h-9 rounded border;
    background-color: var(--background-var3); //bg-blanco 
    border-color: var(--onsurface-var1); //border-gris-linea
  }
  input[type=text],   input[type=email],   input[type=tel]{
    @apply  font-quicksand text-16;
    @apply  focus:font-medium ;
    color: var(--onbackground-var1); //text-negro
    background: var(--background-var3); //bg-blanco
    
    &:focus-visible{
      background: var(--background-var3); //focus:bg-blanco
      box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--primary-inv-var2); //focus:ring-morado
    }
  }  
  input[type=password]{
    background-color: var(--background-var3);
    color: var(--onbackground-var1);
    @apply  font-quicksand text-16;
  }  
  input[type=submit]{
    @apply border-0;
  }
  input::placeholder{
    color: var(--onsurface-var1); //#6A6969 
  }
  input:disabled{
    @apply font-quicksand text-16 ;
    &::placeholder{
      color: var(--surface-var2);
    }
    color: var(--surface-var2) !important; //text-gris-textos
    $themes:(
      "clasico":(
        background-color: var(--background-var2), //bg-gris-desabled 
      ),
      "alto-contraste":(
        background-color: var(--ondisabled-var1)
      )
    );
    @include ThemesProperties($themes);


  }

  input[type=radio]{
    -webkit-appearance: none;
	  -moz-appearance: none;
      opacity: 0;
    @apply appearance-none absolute; 
  }
  .radio-boton:hover input[type=radio]:not(:checked) + .radio{
    $themes:(
      "clasico": (
        background: rgba(102, 102, 204, 0.26087)
      ),
      "alto-contraste":(
        background: #faf096a9
      )
    );
    @include ThemesProperties($themes);
  }
  input[type=radio]:checked + .radio:after{
    @apply transform scale-100 opacity-100;
    background-color: var(--primary-inv-var2); //bg-morado
  }

  /* checkbox */
  input[type=checkbox]{
    -webkit-appearance: none;
	  -moz-appearance: none;
      opacity: 0;
    @apply appearance-none; 
  }
  /* checkbox: SI/NO */
  input[type=checkbox ] + .checkbox{
    background-color: var(--surface-var3);
    box-shadow: 0 0 2px 0px rgba(0,0,0.2);
    $themes:(
      "alto-contraste":(
          border: 1px solid var(--onprimary-var1)
      )
  );
  @include ThemesProperties($themes);
    @apply w-14 h-7 absolute right-0 top-0 rounded-full;
  }
  input[type=checkbox] + .checkbox:before{
    content: '';
    background-color: var(--surface-var2);
    $themes:(
      "alto-contraste":(
          border: 2px solid var(--onprimary-var1)
      )
  );
  @include ThemesProperties($themes);
    @apply w-6 h-6 rounded-full absolute z-10 left-1 top-0.5 shadow transition-all;
  }
  input[type=checkbox]:checked + .checkbox:before{
    background-color: var(--primary-inv-var2);
    @apply left-7 transition-all;
  }

  input[type=checkbox]:checked + .checkbox:after{
    opacity: 1;
  }
  input[type=checkbox]:checked + .checkbox{
    $themes:(
        "clasico": (
          background: rgba(102, 102, 204, 0.26087)
        )
      );
      @include ThemesProperties($themes);
  }
  input[type=checkbox] + .checkbox:after{
    content: 'SI';
    top: 3px;
    color: var(--primary-inv-var2);
    @apply font-quicksand font-bold;
    @apply absolute text-14 not-italic  opacity-0 left-2  transition-all;
  }
  input[type=checkbox]+ .checkbox .no-check{
    top: 3px;
    color: var(--onsurface-var1);
    @apply font-quicksand font-bold;
    @apply absolute text-13 not-italic opacity-100 right-1 transition-all;
  }
  
  /* checkbox: CHECK CAJA */
  input[type=checkbox] + .check{
    font-family: 'icomoon' !important;
    @apply w-7 h-7  absolute left-0 -top-1 text-24 not-italic ;
  }
  input[type=checkbox] + .check:after{
    content: "\e90d";
    color: var(--onsurface-var1); //text-negro-lista 
  }
  input[type=checkbox]:checked + .check:after{
    content: "\e90e";
    color: var(--primary-inv-var2); //text-morado   
  }

  select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  select::-ms-expand {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: none bg-blanco;
  }
  textarea{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--background-var3);
    border: 1px solid var(--onsurface-var1); //border
    @apply   rounded-md h-36 w-full;
  }
  textarea::placeholder{
    color: var(--onsurface-var1) !important; //#6A6969 
  }
  textarea:focus{
    @apply font-medium border-none;
  }

.input-select-checkbox{
    .list{
        > .item{
            &:hover{
                background-color: var(--primary-var5);
            }
        }
    }
}