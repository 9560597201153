.libro-tarjeta {
    @apply flex select-none flex-col max-w-min;
}

.libro-tarjeta>.portada>.ver-mas {
    transition: background-color .3s, opacity .3s, visibility .3s;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    @apply absolute top-0 left-0 min-w-full min-h-full flex center pointer-events-none;
}

.libro-tarjeta:hover>.portada>.ver-mas {
    background-color: rgba(0, 0, 0, .10);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}


.portada {
    background-color: var(--background-var3);
    @apply flex flex-col rounded-md transform shadow-2 transicion-300;
}

.libro-tarjeta>.titulo {
    color: var(--onbackground-var1);
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;

    @supports (-webkit-line-clamp: 2) {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: initial !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical !important;
    }
    @apply quicksand-700 text-17 text-center transicion-300 cursor-pointer;
}

.libro-tarjeta .fav {
    // visibility: hidden;
    background-color: var(--primary-var1);
    color: var(--onprimary-var1);
    @apply transicion-300 opacity-0 cursor-pointer;
}

.libro-tarjeta .fav.added {
    // visibility: visible;
    background-color: var(--primary-inv-var1);
    color: var(--red-var1);
    @apply opacity-100;
}

.libro-tarjeta .fav:hover:not(.added) {
    // visibility: visible;
    background-color: var(--primary-var1);
    color: var(--onprimary-var1);
    @apply opacity-100;
}


.libro-tarjeta:hover>.portada {
    @apply -translate-y-1 shadow-5;
}

.libro-tarjeta:hover>.titulo {
    color: var(--primary-inv-var2);
}

.libro-tarjeta:hover>.portada>.fav,
.libro-tarjeta>.portada>.fav:focus-visible {
    // visibility: visible;
    @apply opacity-100;
}