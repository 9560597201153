
$outline-size: 2px;

.focusable{
  @include focusable(solid, #{$outline-size} , var(--onbackground-var1) );
}

.focusable{
    &-primary{
        @include focusable(solid, #{$outline-size} , var(--primary-inv-var1));
    }
    &-secondary{
        @include focusable(solid, #{$outline-size} , var(--secondary-inv-var1));
    }
    &-red{
        @include focusable(solid, #{$outline-size} , var(--red-inv-var1));
    }
    &-green{
        @include focusable(solid, #{$outline-size} , var(--green-inv-var1));
    }
    &-blue{
        @include focusable(solid, #{$outline-size} , var(--blue-inv-var1));
    }
}

.focusable-by-children{
  @include focusable(solid, #{$outline-size} , var(--onbackground-var1) );
}

.focusable-by-children{
    &-primary{
        @include focusableByChildren(solid, #{$outline-size} , var(--primary-var1));
    }
    &-secondary{
        @include focusableByChildren(solid, #{$outline-size} , var(--secondary-var1));
    }
    &-red{
        @include focusableByChildren(solid, #{$outline-size} , var(--red-var1));
    }
    &-green{
        @include focusableByChildren(solid, #{$outline-size} , var(--green-var1));
    }
    &-blue{
        @include focusableByChildren(solid, #{$outline-size} , var(--blue-var1));
    }
}



