
/*fuentes*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

/*OpenDyslexic-alta-Regular*/
@font-face {
    font-family: 'OpenDyslexic';
    font-style: normal;
    font-weight: 300;
    src: local(''), 
      url('/fonts/openDyslexic/OpenDyslexicAlta-Regular.otf') format('opentype');
  }
  /*OpenDyslexic-Regular*/
  @font-face {
    font-family: 'OpenDyslexic';
    font-style: normal;
    font-weight: 400;
    src: local(''), 
      url('/fonts/openDyslexic/OpenDyslexic-Regular.otf') format('opentype');
  }
  /*OpenDyslexic-Bold*/
  @font-face {
    font-family: 'OpenDyslexic';
    font-style: normal;
    font-weight: 700;
    src: local(''), 
      url('/fonts/openDyslexic/OpenDyslexic-Bold.otf') format('opentype');
  }
  /*OpenDyslexic-BoldItalic*/
  @font-face {
    font-family: 'OpenDyslexic';
    font-style: italic;
    font-weight: 700;
    src: local(''), 
      url('/fonts/openDyslexic/OpenDyslexic-BoldItalic.otf') format('opentype');
  }
  /*OpenDyslexic-Italic*/
  @font-face {
    font-family: 'OpenDyslexic';
    font-style: italic;
    font-weight: 400;
    src:  local(''), 
      url('/fonts/openDyslexic/OpenDyslexic-Italic.otf') format('opentype');
  }

body[data-font='dyslexic']{
    --primary-font:'openDyslexic';
    --secondary-font:'openDyslexic';
  }
  
  body[data-font='predeterminado']{
    --primary-font:'Raleway' !important;
    --secondary-font:'Quicksand' !important;
  }
  
  body[data-font='times-new-roman']{
    --primary-font:'Times New Roman';
    --secondary-font:'Times New Roman';
  }
  
  body[data-font='arial']{
    --primary-font:'Arial';
    --secondary-font:'Arial';
  }
  

  body[data-font='arial'], 
  body[data-font='times-new-roman'],
  body[data-font='dyslexic']{
    *:not(span):not(i){
        font-family: var(--primary-font);
    }
  }
  

  
  .secondary-font{
    font-family: var(--secondary-font);
}

.primary-font{
      font-family: var(--primary-font);
  }
