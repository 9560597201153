@font-face {
  font-family: 'icomoon';
  src:  url('../../iconos/icomoon.eot?5u9poi');
  src:  url('../../iconos/icomoon.eot?5u9poi#iefix') format('embedded-opentype'),
    url('../../iconos/icomoon.ttf?5u9poi') format('truetype'),
    url('../../iconos/icomoon.woff?5u9poi') format('woff'),
    url('../../iconos/icomoon.svg?5u9poi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-medalla:before {
  content: "\e937";
}
.icon-reloj:before {
  content: "\e938";
}
.icon-corona:before {
  content: "\e936";
}
.icon-tiempo:before {
  content: "\e935";
}
.icon-libro-album:before {
  content: "\e92a";
}
.icon-cronica-y-ensayo:before {
  content: "\e92e";
}
.icon-cuento-ilustrado:before {
  content: "\e933";
}
.icon-copiar:before {
  content: "\e928";
}
.icon-didactico:before {
  content: "\e92b";
}
.icon-informativo:before {
  content: "\e92c";
}
.icon-interactivo:before {
  content: "\e92d";
}
.icon-novela:before {
  content: "\e92f";
}
.icon-poesia:before {
  content: "\e930";
}
.icon-teatro:before {
  content: "\e931";
}
.icon-comic:before {
  content: "\e932";
}
.icon-cuento:before {
  content: "\e934";
}
.icon-microsoft:before {
  content: "\e924";
}
.icon-atras:before {
  content: "\e923";
}
.icon-profesor:before {
  content: "\e920";
}
.icon-mover:before {
  content: "\e922";
}
.icon-advertencia:before {
  content: "\e91f";
}
.icon-correo:before {
  content: "\e91e";
}
.icon-anterior:before {
  content: "\e915";
}
.icon-siguiente:before {
  content: "\e916";
}
.icon-descargar:before {
  content: "\e912";
}
.icon-ver:before {
  content: "\e913";
}
.icon-no-ver:before {
  content: "\e914";
}
.icon-estadisticas:before {
  content: "\e900";
}
.icon-estadisticas-barra:before {
  content: "\e901";
}
.icon-carita:before {
  content: "\e909";
}
.icon-carita-redonda:before {
  content: "\e90a";
}
.icon-alerta:before {
  content: "\e902";
}
.icon-archivar:before {
  content: "\e903";
}
.icon-basura:before {
  content: "\e904";
}
.icon-birrete:before {
  content: "\e905";
}
.icon-calendario:before {
  content: "\e906";
}
.icon-candado-close:before {
  content: "\e907";
}
.icon-candado-open:before {
  content: "\e908";
}
.icon-cerrar:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-checkbox:before {
  content: "\e90d";
}
.icon-check-cuadro:before {
  content: "\e90e";
}
.icon-compartir:before {
  content: "\e90f";
}
.icon-compartir-small:before {
  content: "\e910";
}
.icon-corazon:before {
  content: "\e911";
}
.icon-lapiz:before {
  content: "\e917";
}
.icon-libreta:before {
  content: "\e918";
}
.icon-libro:before {
  content: "\e919";
}
.icon-lupa:before {
  content: "\e91a";
}
.icon-marcador:before {
  content: "\e91b";
}
.icon-mas:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e91d";
}
.icon-select:before {
  content: "\e925";
}
.icon-subir:before {
  content: "\e926";
}
.icon-usuario:before {
  content: "\e927";
}
.icon-volver:before {
  content: "\e929";
}
.icon-library:before {
  content: "\e921";
}
.icon-phone:before {
  content: "\e942";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-spinner7:before {
  content: "\e980";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-shrink:before {
  content: "\e98a";
}
.icon-cog:before {
  content: "\e994";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-google:before {
  content: "\ea88";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-chrome:before {
  content: "\ead9";
}
.icon-edge:before {
  content: "\eadc";
}
.icon-safari:before {
  content: "\eadd";
}
