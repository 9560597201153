.splash-screen{
    z-index: 9999999;
    transition: transform .3s;
    background-color: var(--background-var3); //bg-blanco
    @apply w-screen h-screen flex flex-col center fixed ;
}

.splash-screen.ocultar{
  transform: translateY(-100vh);
}

.splash-screen .logo{
    width: 50vw;
    height: auto;
    min-width: 200px;
    animation: animacionLogo 1s 0s both;   
  }
  
  .splash-screen .spinner{
    color: var(--primary-inv-var2); //text-morado
    @apply text-34 animate-spin m-2; 
}

@keyframes animacionLogo {
    0%{
      opacity: 0;  
      transform: scale(0);
    }
    50%{
        transform: scale(1.5);
      }
    
      75%{
        transform: scale(.85);
      }

    100%{
        opacity: 1;  
        transform: scale(1);
      }
}

